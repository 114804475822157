import * as React from "react";

export default class Home extends React.Component<any, any> {
    render() {
        return (
            <article id='article-home' className={'container'}>
                <h1>Werkplanprüfung – nutzen Sie unsere Erfahrung</h1>
                <h2>Faule Eier in Bauplänen? Nicht mit Planalyze!</h2>

                <h3>4-Augen-Prinzip für fehlerfreie Werkpläne</h3>
                <p>
                    Sie wollen der Baustelle fehlerfreie Pläne abgeben, so dass der nächste Bau reibungslos
                    abläuft? Verhindern Sie teure Terminverzögerungen und ärgerliche Mehrkosten mit den Profis
                    von Planalyze!
                </p>

                <h3>Bereit für die Baustelle</h3>
                <p>Von Architekten über Baumanagementbüros bis zu Bauherrschaften: Wir überprüfen Ihre Werkpläne
                    nach SIA 400 und machen die faulen Eier in Bauplänen ausfindig. Ihre Vorteile? Kein
                    Nervenflattern, keine Terminverzögerungen, keine Mehrkosten!</p>

                <div className={'home-eggs'}>
                    <div className={'row'}>
                        <div className={'col-md-2'} id={'egg-title-1'}>
                            <h2>Ist der Plan <br/>baustellen&shy;tauglich?</h2>
                        </div>
                        <div className={'col-md-4 card-container'} id={'egg-img-1'}>
                            <div className={'card-flip'}>
                                <div className={'card bg-light front'}>
                                    <img src={require('../../Images/Planalyze-PlanEi_Q.png')} alt=""/>
                                </div>
                                <div className={'card bg-light back'}>
                                    <p className={'container-text'}>
                                        Wir prüfen die korrekte <strong>Darstellung nach SIA 400</strong> und
                                        analysieren die <strong>Masshierarchie</strong>. Zudem kontrollieren wir
                                        die <strong>Texthierarchie</strong> und achten auf
                                        die <strong>Übersichtlichkeit</strong> des Plans.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-4 card-container'} id={'egg-img-2'}>
                            <div className={'card-flip'}>
                                <div className={'card bg-light front'}>
                                    <img src={require('../../Images/Planalyze-BachstEi_Q.png')} alt=""/>
                                </div>
                                <div className={'card bg-light back'}>
                                    <p className={'container-text'}>
                                        Wir machen allfällige <strong>Konstruktionsfehler</strong> in Ihren Werkplänen
                                        ausfindig. Grundlage sind stets die <strong>SIA-Normen</strong>.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-2'} id={'egg-title-2'}>
                            <h2>Gibt es <br/>Konstruktions&shy;fehler?</h2>
                        </div>
                    </div>
                    <div className={'home-eggs'}>
                        <div className={'row'}>
                            <div className={'col-md-2'} id={'egg-title-3'}>
                                <h2>Wurden alle Vorgaben <br/>eingehalten? </h2>
                            </div>
                            <div className={'col-md-4 card-container'} id={'egg-img-3'}>
                                <div className={'card-flip'}>
                                    <div className={'card bg-light front'}>
                                        <img src={require('../../Images/Planalyze-NestEi_Q.png')} alt=""/>
                                    </div>
                                    <div className={'card bg-light back'}>
                                        <p className={'container-text'}>
                                            Wir stellen sicher, dass
                                            keine <strong>Bauprojektvorgaben</strong> oder <strong>Fachplanervorgaben</strong>
                                            vergessen gehen. Zudem kontrollieren wir, ob
                                            der <strong>Baubeschrieb</strong> und
                                            die <strong>F+M-Konzepte</strong> berücksichtigt wurden.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-4 card-container'} id={'egg-img-4'}>
                                <div className={'card-flip'}>
                                    <div className={'card bg-light front'}>
                                        <img src={require('../../Images/Planalyze-ZundEi_Q.png')} alt=""/>
                                    </div>
                                    <div className={'card bg-light back'}>
                                        <p className={'container-text'}>
                                            Neben den <strong>SIA-Normen</strong> prüfen wir, ob auch die
                                            spezifischen <strong>Gesetze und
                                            Verordnungen</strong> eingehalten wurden. Wir kontrollieren, ob die <strong>Auflagen
                                            aus der Baubewilligung</strong> eingeflossen sind und ob die
                                            wichtigsten <strong>Verbandsnormen</strong> berücksichtigt wurden.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-md-2'} id={'egg-title-4'}>
                                <h2>Wurden <br/>die Normen <br/>eingehalten?</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        );
    }
}
