import React from 'react';

export default class About extends React.Component<any, any> {
    render() {
        return (
            <article id='article-about' className={'container'}>
                <h1>Erfahrung und Treffsicherheit</h1>
                <p><strong>Hinter Planalyze steht die <a href={'https://www.arch-on.ch/'}
                                                         target={'blank'}> Arch-on!</a> Wir bringen jahrzehntelange
                    Erfahrung und den notwendigen
                    Blick fürs Detail mit.</strong></p>
                <p>Wir haben das Handwerk von der Pike auf gelernt: Als <strong>Bauzeichnerin und
                    Bauzeichner</strong> kennen wir uns mit dem Anfertigen und Prüfen von Bauplänen bestens aus.
                    Als <strong>Architektin, Architekt und Bauherren</strong> arbeiten wir auf den unterschiedlichsten
                    Baustellen. Dabei haben wir <strong>über 20 Jahre Erfahrung</strong> in der konstruktiven
                    Zusammenarbeit auf allen Ebenen gesammelt. Von Handwerkern über Fachplaner bis zu Zulieferern: Wir
                    wissen, wie die Zahnräder einer Baustelle am besten ineinandergreifen. Und wir wissen, wo am meisten
                    Fehler passieren – <strong>Fehler, die mit unserer Beratung vermieden werden können</strong>.</p>
                <p>Wir kennen das Metier in- und auswendig: <strong>Gern teilen wir unsere Erfahrung und unser Können</strong>.</p>
                <p className={'list-heading'}><strong>Planalyze ist ein einzigartiges Dienstleistungsangebot für:</strong></p>
                <ul>
                    <li>Architekten und Architektinnen</li>
                    <li>Baumanagementbüros</li>
                    <li>Generalunternehmerinnen und Generaluntehmer</li>
                    <li>Totalunternehmer und Totalunternehmerinnen</li>
                    <li>Bauherrenvertreterinnen und Bauherrenvertreter</li>
                    <li>Bauherrschaften</li>
                </ul>
            </article>
        );
    }
}