import * as React from "react";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import Home from "./Content/Home";
import Contact from "./Content/Contact";
import Why from "./Content/Why";
import About from "./Content/About";
import Downloads from "./Content/Downloads";

class MainRouter extends React.Component<any, any> {
    render() {
        return (
            <HashRouter>
                <HeaderComponent/>
                <section id='content' className={'container'}>
                    <Switch>
                        <Route exact path="/">
                            <Redirect to="/home" />
                        </Route>
                        <Route path='/home' exact={true} render={() => <Home/>}/>
                        <Route path='/contact' exact={true} render={() => <Contact/>}/>
                        <Route path='/why' exact={true} render={() => <Why/>}/>
                        <Route path='/about' exact={true} render={() => <About/>}/>
                        <Route path='/downloads' exact={true} render={() => <Downloads/>}/>
                    </Switch>
                </section>
            </HashRouter>
        );
    }
}

export default MainRouter;
