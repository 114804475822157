import React from "react";

export default class Contact extends React.Component<any, any> {
    render() {
        return (
            <article id='article-contact' className={'container'}>
                <h1>Planalyze - für fehlerfreie Werkpläne</h1>
                <p><strong>Interesse? Fragen? Schreiben Sie uns oder rufen Sie uns an!</strong></p>
                <p>Claudia & Erich Köppel</p>
            </article>
        );
    }
}