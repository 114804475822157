import * as React from "react";
import {Navbar, Nav} from "react-bootstrap";

interface MainNavigationState {
    menuIsOpen: boolean
}

class MainNavigation extends React.Component<any, MainNavigationState> {
    render() {
        return (
            <React.Fragment>
                <Navbar bg={'light'} expand={false} className={'col-2'} collapseOnSelect={true}>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="#home">Home</Nav.Link>
                            <Nav.Link href="#contact">Kontakt</Nav.Link>
                            <Nav.Link href="#why">Darum Planalyze</Nav.Link>
                            <Nav.Link href="#about">Über Planalyze</Nav.Link>
                            <Nav.Link href="#downloads">Downloads</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </React.Fragment>
        );
    }
}

export default MainNavigation
