import * as React from "react";
import MainNavigation from "./MainNavigation";
import {Link} from "react-router-dom";

class HeaderComponent extends React.Component<any, any> {
    render() {
        return (
            <section id='header' className={'container'}>
                <nav className={'row'}>
                    <div className={'nav-menu-logo col-10'}>
                        <Link to={'/'}>
                            <img src={require('../Images/logo_planalyze.png')} alt="Planalyze Logo"/>
                        </Link>
                    </div>
                    <MainNavigation/>
                </nav>
            </section>
        );
    }
}

export default HeaderComponent;
