import React from 'react';

export default class Why extends React.Component<any, any> {
    render() {
        return (
            <article id='article-why' className={'container'}>
                <h1>Sicherheit dank Planalyze</h1>
                <p><strong>Die wenigsten Werkpläne und Baupläne sind fehlerfrei; dies hat nachvollziehbare Gründe - es
                    muss aber nicht sein.</strong></p>
                <p>Wer lange und intensiv an einem Projekt arbeitet, übersieht schnell Fehler. Hinzu kommen Druck, Eile
                    und neue Anforderungen – alles Fehlerquellen, die auch den Besten ein Ei legen können. Wir wissen
                    genau, wo wir suchen müssen, um die <strong>häufigsten Fehler schnell und treffsicher zu
                        finden</strong>. Das <strong>Vier-Augen-Prinzip von Planalyze</strong> hat sich dabei als
                    zusätzlicher Vorteil bewährt.</p>
                <p className={'list-heading'}><strong>Ihre Vorteile mit Planalyze</strong></p>
                <ul>
                    <li>fehlerlose Werkpläne und Baupläne</li>
                    <li>keine Terminverzögerungen</li>
                    <li>keine Konstruktionsfehler</li>
                    <li>keine aufwändigen Korrekturmassnahmen</li>
                    <li>keine Mehrkosten</li>
                    <li>keien unzufriedenen Kunden</li>
                    <li>kein Nervenflattern, kein Verdruss</li>
                </ul>
            </article>
        );
    }
}